<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <div
      v-if="showViewAsRetaler && selectedDirectRetailer"
      class="retailer-tag"
    >
      You are viewing as: {{ selectedDirectRetailer.entityName }} {{ selectedDirectRetailer.retailerBrandIdentity && `(${selectedDirectRetailer.retailerBrandIdentity})` }}
      <b-button
        variant="custom-secondary"
        class="ml-1"
        @click="openDownloadCollection"
      >
        Download Collection
      </b-button>
    </div>
    <!--/ Navbar -->

    <b-navbar
      v-if="$store.state.shop.showMultiSelectNavBarForProductsPage"
      class="header-navbar d-flex popup-navbar-v2 navbar navbar-shadow align-items-end"
    >
      <app-horizontal-layout-multi-select />
    </b-navbar>

    <b-navbar
      v-if="isBrand && isSelectedMultipleSelections"
      class="header-navbar navbar navbar-shadow align-items-center  multi-select"
    >
      <app-horizontal-layout-selection-multi-select />
    </b-navbar>

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
        :class="{'sticky-retailer-tag': showViewAsRetaler && selectedDirectRetailer }"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <!-- Cookies Banner -->
    <div>
      <slot name="cookies">
        <app-cookies />
      </slot>
    </div>
    <!-- /Cookies Banner -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import AppCookies from '@core/layouts/components/AppCookies.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar, BButton } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import AppHorizontalLayoutMultiSelect from '@/@core/layouts/components/app-navbar/AppHorizontalLayoutMultiSelect.vue'
import UserRoleMixinVue from '@/views/apps/UserRoleMixin.vue'
import { productsListRouteName } from '@/router/routes/apps'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import AppHorizontalLayoutSelectionMultiSelect from '../components/app-navbar/AppHorizontalLayoutSelectionMultiSelect.vue'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    AppCookies,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    AppHorizontalLayoutMultiSelect,
    BButton,
    AppHorizontalLayoutSelectionMultiSelect,
  },
  mixins: [mixinVerticalLayout, UserRoleMixinVue],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
    showViewAsRetaler() {
      return this.isBrand && productsListRouteName === this.$route.name
    },
    selectedDirectRetailer() {
      return this.$store.getters.selectedDirectRetailer
    },
    isSelectedMultipleSelections() {
      return !!this.$store.getters?.getSelectedSelections?.length
    },
  },
  methods: {
    openDownloadCollection() {
      this.$root.$refs.shopItems.openDownloadCollection()
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";

.popup-navbar-v2 {
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  background: transparent;
}
</style>
