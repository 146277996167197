<template>
  <div class="navbar-container d-inline-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-inline-block d-xl-none mr-auto">
      <li class="nav-item">
        <b-link
          class="nav-link color-white"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-md-block">
      <app-breadcrumb :show-header="false" />
    </div>
    <b-navbar-nav
      class="nav nav-right align-items-center ml-0 ml-xs-auto"
      align="right"
    >
      <b-button
        v-if="isDisplayCancel"
        class="nav-item-margin-left"
        variant="outline-danger"
        @click="cancelReplaceCartProduct"
      >
        Cancel Replace
      </b-button>
      <wishlist-dropdown
        v-if="showWishlists"
        class="nav-item-margin-left"
      />
      <direct-retailer-drop-down
        v-if="showViewAsRetaler"
        class="nav-item-margin-left"
      />
      <span
        v-if="!isGuest && isBrand && currency"
        class="nav-item-margin-left"
      >
        {{ `Currency - ${currency}` }}
      </span>
      <b-link
        v-if="isRetailer"
        :to="{name: 'checkout'}"
        class="nav-item-margin-left"
      >
        <feather-icon
          class="text-white"
          icon="ShoppingCartIcon"
          size="21"
          :badge="cartItemsCount || ''"
          badge-classes="cart-badge"
        />
      </b-link>
      <send-mail-notification v-if="isBrand" />
      <switch-profile-or-logout
        v-if="profile || isGuest"
        class="nav-item-margin-left"
      />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton,
} from 'bootstrap-vue'

import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import SendMailNotification from '@/views/apps/notification/SendMailNotification.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import { mapState } from 'vuex'
import { SET_REPLACE_CART_PRODUCT } from '@/store/modules/shop.module'
import SwitchProfileOrLogout from './components/SwitchProfileOrLogout.vue'

const WishlistDropdown = () => import('./components/WishlistDropdown.vue')
const DirectRetailerDropDown = () => import('./components/DirectRetailerDropDown.vue')

export default {
  components: {
    BLink,
    BButton,
    // Navbar Components
    BNavbarNav,
    AppBreadcrumb,
    SwitchProfileOrLogout,
    SendMailNotification,
    WishlistDropdown,
    DirectRetailerDropDown,
},
  mixins: [UserRoleMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profileData,
      cartItemsCount: state => state.shop.cartItemsCount,
    }),
    currency() {
      return this.profile?.currency
    },
    showWishlists() {
      return ['wishlist-products', 'selection-products'].indexOf(this.$route.name) > -1
    },
    showViewAsRetaler() {
      return this.isBrand && ['collection/products'].indexOf(this.$route.name) > -1
    },
    isDisplayCancel() {
      const replacingProduct = this.$store.getters.getReplaceCarProduct
      const isCollectionOrCheckoutRoute = ['collection/products', 'checkout'].includes(this.$route.name)
      const isSameCollectionId = replacingProduct?.collectionId === this.$route?.params?.collectionId

      if (isCollectionOrCheckoutRoute && replacingProduct) {
        if (isSameCollectionId) {
          return true
        }
        return false
      }

      this.$store.commit(SET_REPLACE_CART_PRODUCT, null)
      return false
    },
  },
  methods: {
    async cancelReplaceCartProduct() {
      this.$store.commit(SET_REPLACE_CART_PRODUCT, null)
      await this.$router.push({ name: 'checkout' })
    },
    openBroadcastMessage() {
      this.$bvModal.show('send-mail-notification')
      analytics.track(constants.TRACKS.PAGES.BRAND_OPENS_MESSAGE_FROM)
    },
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
.navbar-container {
  .breadcrumb-item {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.cart-badge {
  background: $cart-icon-badge-bg;
  color: $white;
}
.logout-span{
  margin-top: 4px;
  margin-left: 5px;
}
.nav-item-margin-left {
  margin-left: 13px
}
</style>
